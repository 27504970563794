<!--
 * @Author: Leif
 * @Date: 2021-11-05 13:44:27
 * @LastEditTime: 2022-08-18 23:46:20
 * @LastEditors: ch3nh2
 * @Description: 会议调度 - 会议调度首页
 * @FilePath: /zhy_dataview_fe/src/pages/NoticeSchedule/index.vue
-->
<template>
  <div class="wrap">
    <HomeHead :isHome="true" />
    <div class="container">
      <div class="main">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import HomeHead from "components/HomeHead";
export default {
  name: "NoticeSchedule",
  data() {
    return {
      EventLogTran: false,
    };
  },
  components: {
    HomeHead,
  },
  props: {},
  methods: {
    goBack() { },
  },
  created() { },
  beforeUnmount() { },
};
</script>

<style lang="scss">
</style>
